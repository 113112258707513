import { useUI } from "@/hooks/useUIStore";
import { ServerProps } from "@/shared/serverProps";
import { Attribute, Entity, GenericProfile, PipelineRunStatus } from "@/types";
import { usePostHog } from "posthog-js/react";

export type EntityPageProps = {
  entity: Entity | undefined;
  // Used for OpenGraph
  ogDescription?: string;
  aliases?: string[];
  todayEntityVisits?: string[];
  attributes: Attribute[];
  status: PipelineRunStatus;
  snapshot: GenericProfile;
  lastUpdated?: string;
} & ServerProps;

export const useEntityPage = (props: EntityPageProps) => {
  const posthog = usePostHog();
  const { entity, attributes, status, snapshot, aliases, ...rest } = props;
  useUI(rest);

  /*
  useEffect(() => {
    const entityStore = getMainEntityStore();
    if (entity) {
      void entityStore.load(entity, snapshot, { attributes, aliases, status });
      posthog?.capture("view-entity", {
        entityId: entity.id,
        type: entity.type,
        name: entity.name,
      });
    }
  }, [entity, attributes, snapshot, aliases, posthog, status]);
   */
};
